.mxr__values.mxr__section {
    background: #f2f2f2;
}
.mxr__values .mxr__tabs .nav-link.active {
    border-color: #f2f2f2 #f2f2f2 #4579ad #f2f2f2;
    border-width: 2px;
    background: #f2f2f2 !important;
    border-bottom: 2px solid #4579ad !important;
}
.mxr__timeline .time_line-icon_text h4 {
    font-size: 1.2rem;
    font-weight: 800;
}
.mxr__values li {
    width: 50% !important;
    padding: 2px 30px;
    list-style-type: none;
    text-align: center;

}
.mxr__values ul {
    width: 100%;
    display: flex;
    align-items: flex-start !important;
    justify-content: center;
    list-style: none;
}
.mxr__values label {
    font-size: 18px !important;
    color: #4579ad;
    font-weight: 600;
    margin-bottom: 11px;
    margin-top: 20px;
}
.mxr__values p {
    color: #777777;
    font-family: 'Poppins';
    font-weight: 400 !important;
        margin-top: 20px;
}
.mxr__values .tab-content {
    margin-top: 40px;
}

@media screen and (max-width: 767px){
.mxr__values ul {
    width: 100%;
    display: block;
    align-items: flex-start !important;
    justify-content: center;
    list-style: none;
    padding-left: 0;
}
.mxr__values li {
    width: 100% !important;
    padding: 2px 30px;
    list-style-type: none;
    text-align: center;
}
.mxr__values .mxr__tabs .nav-link {
    color: #777;
    padding: 10px 20px !important;
    border-color: rgb(242 242 242) !important;
    background: #d5d5d5 !important;
    margin-bottom: 2px;
}
}  