@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300&display=swap");

:root {
  --font-family: "Poppins", sans-serif;

  --color-primary-bg: #4579ad;
  --color-footer: #031b34;
  --color-blog: #042c54;
  --color-text: #383838;
  --color-subtext: #ff8a71;
  --color-heading: #4579ad;
  --color-white-bg-link: #4579ad;
}

body {
  font-family: var(--font-family);
}

.mxr__section {
  padding: 50px 0;
}
#Services [type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: unset !important;
}
#Technologies [type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: unset !important;
}
.mxr__section-title {
  margin: 0px;
  color: var(--color-heading);
  font-size: 15px;
  line-height: 1.5;
  text-align: center;
  font-weight: 500;
  padding-bottom: 1.5rem;
}

.card-text {
  line-height: 1.6;
  font-size: 15px;
}

a {
  color: var(--color-heading);
  text-decoration: none;
}

p {
  color: #777;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 400;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgb(255 255 255);
}

.mxr__tabs {
  border: none;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
}

.mxr__tabs .nav-link {
  padding: 10px 30px;
  color: #777;
  border: unset !important;
}
.heading_para {
  border-bottom: 1px solid #ebe1e1;
  padding-bottom: 14px;
}
.mxr__blog-single-post li {
  color: #777777;
  line-height: 30px;
  font-weight: 400;
}
.bottm_bar p {
  display: inline-flex;
}
.bottm_bar a.nav-link {
  padding: 0px 6px;
}
.nav-link {
  font-size: 14px;
}
.ploicy_page h3.mxr__section-title {
  text-align: left;
  font-size: 24px;
}
.ploicy_page {
  padding-bottom: 34px;
}
.ploicy_page li {
  color: #777;
  font-size: 15px;
  line-height: 31px;
}
.social_links_bottom a {
  display: unset !important;
  border-radius: 50px !important;
  width: 40px !important;
  margin: 13px !important;
  height: 40px !important;
  padding: 7px;
  float: right;
}
.bottm_bar.row {
  background: whitesmoke;
  padding: 24px 0px;
}
.mxr__blog-single-post h3 {
  color: #2a2a2a !important;
  font-weight: unset;
  font-size: 24px;
  padding: 26px 0px;
}
.mxr__blog-single-post h1.mt-5 {
  padding-bottom: 9px;
}
.blog_content_info p {
  padding-top: 20px;
}
.mxr__whoweare .mxr__section-title {
  margin: 0px;
  color: var(--color-heading);
  font-size: 33px;
  line-height: 1.5;
  text-align: center;
  font-weight: 600;
  padding-bottom: 1rem;
}
.mxr__blog-content img {
  width: 100%;
}
.mxr__tabs .nav-link:hover {
  border: none;
}
.blog_content_info {
  padding: 30px 25px 20px 25px;
}
.mxr__readytotalk.mxr__section {
  padding-top: 0;
}

.mxr__tabs .nav-link.active {
  border-width: 2px;
  border-bottom: 2px solid #4579ad !important;
}
.mxr__section-btn {
  border-color: #105378;
  background: #4579ad !important;
  font-size: 13px !important;
  color: #ffff !important;
  font-weight: 600;
  border-radius: 4px !important;
  padding: 10px !important;
  margin-top: 10px;
  width: 180px;
  border: unset !important;
  text-transform: uppercase;
}
.landscap_left_sec.col-md-6.col-sm-12 {
  text-align: center;
}
.mxr__benifits h3.mxr__section-title {
  margin-top: 12%;
}
.mxr__stack-list {
  float: left;
  width: 100%;
  display: flex;
  align-items: flex-start !important;
  justify-content: center;
  list-style: none;
  margin: 20px 0px;
}

.mxr__stack-list li {
  width: 20%;
  text-align: center;
  color: #383838;
  font-weight: 600;
}

.mxr__stack-list li > div {
  display: block;
}

.mxr__stack .tab-content {
  display: inline-block;
}

.text_center_withicon .col-lg-4.col-sm-12 {
  text-align: center;
  padding-top: 5%;
}

.text_center_withicon h5 {
  color: #4579ad;
  font-weight: 800;
  margin-bottom: 15px;
}

.mxr__platform.mxr__section {
  text-align: CENTER;
}

.mxr__hireus p,
.mxr__design p {
  color: #777777 !important;
}

.mxr__hireus svg,
.mxr__design svg {
  color: #4579ad;
  margin-right: 12px;
}

.mxr__hireus h3.mxr__section-title,
.mxr__design h3.mxr__section-title {
  text-align: left;
}
.vertical_tabs {
  background: #4579ad;
}
.vertical_tabs h3.mxr__section-title {
  color: #fff;
}
.vertical_tabs .nav-item {
  text-align: right;
  border-right: 3px solid white;
  margin-top: 8px;
  margin-left: 12px !important;
}
.vertical_tabs .nav-item a {
  color: #fff !important;
  font-weight: 600;
  font-size: 18px;
  padding-right: 0;
  margin-right: 14px;
}
.vertical_tabs .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #4579ad !important;
  border-bottom: 2px solid #fff;
  border-radius: 0;
}

.vertical_tabs .nav-item a:active {
  border-bottom: 2px solid #fff !important;
  padding-right: 0;
  border-radius: 0;
}
.vertical_tabs p {
  color: #fff;
  padding-top: 20px;
}
.mxr__platform.mxr__section {
  background: #e8e8e8;
}
.email svg {
  position: absolute;
  color: #fff;
  font-size: 12px;
  top: 5px;
}
.email.col-md-4.col-sm-12 {
  position: relative;
}
.mxr__project.mxr__section {
  background-image: url("../src/assets/images/contact-banner.png");
  background-position: center center;
  background-repeat: no-repeat;
  border-width: 0px 0px 0px 0px;
  border-color: #3e3e3e;
  border-style: solid;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.mxr__project h3.mxr__section-title {
  color: #fff;
  margin-bottom: 20px;
}
.phone.col-md-4.col-sm-12 {
  position: relative;
}
.mxr__project .inputfild input {
  background-color: rgba(252, 252, 252, 0);
  color: #ffffff;
  border-top-width: 0px;
  border-bottom-width: 2px;
  border-right-width: 0px;
  border-left-width: 0px;
  border-color: #ffffff;
  width: 100%;
  padding-bottom: 9px;
  padding-left: 24px;
  font-size: 14px;
  border-radius: 0px;
}
.phone svg {
  position: absolute;
  color: #fff;
  width: 12px;
  top: 5px;
}
.mxr__project .inputfild .col-md-4.col-sm-12 {
  display: flex;
}
.mxr__project .inputfild .col-md-4.col-sm-12 {
  display: flex;
  margin-bottom: 31px;
}
.mxr__project .container {
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.6);
  border-width: 1px;
  border-color: rgb(255, 255, 255);
  border-style: solid;
  border-radius: 5px;
  overflow: hidden;
  padding: 47px;
  min-height: 0px;
}
.mxr__developmentservice.mxr__section {
  background: #4579ad;
}
.mxr__developmentservice h5 {
  color: #fff;
}
.mxr__developmentservice p {
  color: #fff;
}

.mxr__developmentservice h3.mxr__section-title {
  color: #fff;
  padding-bottom: 33px;
}
.mxr__developmentservice .col-md-6.col-sm-12 {
  display: flex;
}
.icon_leftside_bg {
  background: #ffffff;
  height: 53px;
  width: 54px;
  border-radius: 50%;
  padding: 12px;
  color: #4579ad;
  margin-right: 20px;
}

.mxr__developmentservice .row {
  margin-top: 26px;
}
.project_type.col {
  background: #246366d4 !important;
  border-radius: 6px;
  padding: 20px;
}
.estimate input.slider {
  width: 100%;
  margin-left: 20px;
}
.mxr__project button {
  background: #4579ad !important;
  font-size: 15px !important;
  color: #ffff !important;
  font-weight: 600;
  border-radius: 4px !important;
  padding: 12px 43px !important;
  margin-top: 10px;
  border: unset;
}
.mxr__design h3.mxr__section-title {
  margin-top: 45px;
}
.estimate.col-sm-12 {
  display: flex;
  width: 100%;
  margin: 41px 0px;
}
.mxr__classroom img {
  width: 100%;
}
.estimate .value {
  color: #fff;
  border-bottom: 1px solid;
  padding: 0px 15px;
}
.project_type h6 {
  color: #fff;
}
.internal_page_text_height .card .card-body {
  padding: 0;
  height: 250px;
}
.project_type p {
  color: #fff;
}
.mxr__project h6 {
  color: #fff;
}
.mxr__whychoose h5 {
  color: #4579ad;
  font-weight: 600;
}
.mxr__whychoose .col-md-6.col-sm-12 {
  display: flex;
}
.icon_leftside {
  background: #4579ad;
  height: 53px;
  width: 54px;
  border-radius: 50%;
  padding: 12px;
  color: #fff;
  margin-right: 20px;
}
.white_bg_point .col-md-6.col-sm-12 {
  display: flex;
}

.mxr__whychoose h3.mxr__section-title {
  margin-bottom: 29px;
}
.simple_tabs {
  background: #f2f2f2;
}
.bg_3steps {
  background-color: #4579ad;
}
.simple_tabs {
  background: #f2f2f2;
}
.simple_tabs a.nav-item.nav-link.active {
  background: #f2f2f2;
  border-bottom: 2px solid #4579ad !important;
}
.mxr__advance.mxr__section.bg_3steps {
  background-color: #4579ad;
}
.bg_3steps_icon {
  background: #ffffff;
  height: 54px;
  display: inline-flex;
  width: 54px;
  border-radius: 50%;
  padding: 13px;
  color: #4579ad;
  text-align: center;
  margin-bottom: 19px;
  margin-right: 20px;
}
.mxr__advance2 h4 {
  font-size: 18px;
  line-height: 1.28;
  --fontSize: 18;
  --minFontSize: 18;
  font-weight: 800;
  color: #4579ad;
}
.bg_3steps_icon svg {
  width: 30px;
  height: 30px;
}
.bg_3steps h5 {
  text-align: center;
  color: #fff;
  font-weight: 600;
}
.bg_3steps p {
  color: #fff;
  text-align: center;
}
.bg_3steps .col-md-4.col-sm-12 {
  text-align: center;
  padding: 40px;
}
.bg_3steps h3.mxr__section-title {
  color: #fff;
  margin-bottom: 1%;
}
.bg_3steps .col-md-6.col-sm-12 {
  text-align: center;
  padding: 40px;
}
.bg_3steps {
  background: #4579ad;
}
.mxr__pictures.mxr__section {
  text-align: center;
  width: 100%;
}
.simple_tabs .mxr__tabs .nav-link.active {
  border-color: #f2f2f2 #f2f2f2#4579ad #f2f2f2;
  border-width: 2px;
}

.simple_tabs .tab-content {
  margin-top: 3% !important;
}
.simple_tabs a.nav-item.nav-link.active {
  background: #f2f2f2;
}
.white_simple_tabs .tab-content {
  margin-top: 3%;
}
.white_simple_tabs .tab-content {
  margin-top: 3%;
}
.white_simple_tabs p {
  padding-top: 6%;
}
.mxr__hireus h3.mxr__section-title {
  text-align: left;
  padding-top: 5%;
}
.white_bg_point h3.mxr__section-title {
  margin-bottom: 30px;
}

.icon_textcnetersection {
  background: #4579ad;
  height: 53px;
  width: 54px;
  border-radius: 50%;
  padding: 12px;
  color: #fff;
  margin-right: 0;
  text-align: center;
  margin-bottom: 22px;
  display: -webkit-inline-box;
}
.mxr-reseach-secright img.w-100 {
  height: 100%;
}
.mxr__promise.mxr__section {
  padding-bottom: 0;
}
.mxr__cta2.mxr__section {
  padding-bottom: 0;
}
.mxr__header-align-left span.mxr__header-email {
  font-size: 12px;
}
.mxr__header-email a {
  color: #fff;
}
.mxr__features .col-lg-4.col-md-4.col-sm-4 a {
  color: #4579ad;
  font-weight: 700;
  font-family: "Poppins";
}
.mxr_footer-social-icons span:hover {
  background: #4579ad;
  border: 1px solid #4579ad;
}
.white_bg_point h5 {
  color: #4579ad;
  font-weight: 600;
  font-size: 18px;
}
.mxr__project ::placeholder {
  color: #fff;
}
.list_paragrph p {
  line-height: 0px;
}
.text_center_withicon p {
  padding: 0px 38px;
}
.mxr__features a {
  font-weight: 700;
}
.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 2.8s cubic-bezier(0, 0.81, 0.58, 1) !important;
}
@media screen and (max-width: 767px) {
  .vertical_tabs .nav-item {
    text-align: left;
    border-right: unset;
    margin-top: 8px;
    margin-left: 0px !important;
    margin-bottom: 16px;
  }
  .mxr__whyshould p {
    margin-top: 13px;
  }
  .main_project h3 {
    margin-top: 17px;
  }
  .mxr__whyshouldunity img {
    width: 100%;
  }
  .mxr__whyshould img {
    width: 100%;
  }
  .mxr__secretsauce .card {
    height: 430px !important;
    padding: 30px 15px 15px;
    text-align: center;
    margin: 5%;
  }
  .vertical_tabs img {
    width: 100%;
  }
  .mxr__design img {
    width: 100%;
  }
  .simple_tabs img {
    width: 100%;
    margin-bottom: 20px;
  }
  .mxr__platform img {
    width: 100%;
  }
  .mxr__project .recaptcha.col-md-6.col-sm-12 {
    margin-top: 23px;
  }
  .landscap_left_sec img {
    width: 100%;
  }
  .mxr__pictures img {
    width: 100%;
  }
  .mxr__footer .mxr__section-btn {
    width: 100%;
  }
  .mxr__features .col-lg-4.col-md-4.col-sm-4 a {
    margin-top: 22px;
  }
  .mxr__features .card .card-title {
    color: #383838;
    font-size: 18px !important;
    font-weight: 600 !important;
  }
  .mxr__clients {
    padding: 50px 30px !important;
  }

  .mxr__footer-copyright {
    padding: 20px 0 0 0px;
  }
  .contact_pagge {
    padding: 34px 30px !important;
  }
  .mxr__features .card .card-text {
    font-weight: 400 !important;
  }
  .mxr__solution .card .card-body {
    padding: 0;
    height: auto !important;
  }

  .mxr__clients span.carousel-control-prev-icon {
    border: 1px solid #858585;
    padding: 12px;
  }
  .mxr__clients .carousel-item {
    height: 504px;
  }
  .mxr__clients span.carousel-control-next-icon {
    border: 1px solid #858585;
    padding: 12px;
  }
  .mxr__features .card .card-text {
    color: #777;
    margin-bottom: 0px;
    font-weight: 400 !important;
    line-height: 23px !important;
    font-size: 14px !important;
  }
  .mxr__features .card-body p.card-text {
    height: auto !important;
  }
  .mxr-reseach-secright img.w-100 {
    height: 100%;
    display: none;
  }
  .mxr-reseach-secright {
    color: #fff;
    padding: 15px 0px 0px 0px;
  }
  .mxr__research-border3 {
    border-right: unset !important;
  }
  div#research {
    padding: 0px 30px !important;
    text-align: center;
  }
  .mxr__advance2 .vertical-timeline-element-content-arrow {
    height: 182px;
  }
  .internal_page_banner.blog_banner {
    display: none;
  }
  .mxr__stats-number-txt h4 {
    color: #fff !important;
    font-size: 16px !important;
    padding: 0 !important;
    margin: 0 !important;
    font-family: "Poppins" !important;
    line-height: 20px;
  }
  .mxr__section-btn {
    padding: 8px 15px !important;
  }
  .mxr__clients .carousel-item {
    height: auto !important;
  }
  .mxr__whyshouldunity p {
    margin-top: 20px;
  }
  .mxr__contact svg {
    margin-right: 12px;
  }
  .dropdown a.nav-link:hover {
    color: #918686 !important;
  }
  #Services .dropdown a.nav-link.active {
    color: #ffffff !important;
    background: #4579ad;
  }
  .bottm_bar p {
    color: #777;
    font-size: 12px;
    line-height: 1.6;
    font-weight: 400;
    letter-spacing: 1px;
  }
}
