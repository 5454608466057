.carousel-caption {
    color: #7e7e7e;
    left: 15%;
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;
    position: unset;
    right: 15%;
    text-align: center;
}

.carousel-item-next, .carousel-item-prev, .carousel-item.active {
    display: block;
    text-align: center !important;
}
.slide span.sr-only {
    display: none;
}
.carousel.slide.carousel-fade .carousel-caption {
    position: absolute;
    top: 50%;
    padding: 0;
    z-index: 5;
    transform: translateY(-50%);
    text-align: left; 
    width: 35%;
}
.slide .carousel-caption h3 {
    font-weight: 700;
    font-size: 41px;
    line-height: 49px !important;
    font-family: poppins !important;
    text-transform: uppercase;
    color: #fff;
    text-align: left;
}
.slide .carousel-caption p {
    color: #fff;
}
.carousel-fade .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 800px;
}
@media screen and (max-width: 767px){
    .carousel-fade h3.mxr__section-title {
        font-weight: 900 !important;
        font-size: 19px !important;
        background: #00000094;
        padding: 10px;
        width: 100%;
        float: left;
        line-height: 28px !important;
        margin-bottom: 30px;
    }
    .mxr__section {
        padding: 50px 30px;
    } 
    .mxr__promise .mxr__section-title {
        line-height: 34px;
    }
    .mxr__promise.mxr__section p {
        margin-bottom: 23px;
    }
    .mxr__promise-content {
        padding: 0;
    }
    .mxr__promise.mxr__section {
        text-align: center;
    }
    .mxr__promise .mxr__section-title {
        font-size: 33px;
        color: #383838;
        font-weight: 600;
        text-align: center;
    }
    .carousel-fade .carousel-inner {
        position: relative;
        width: 100%;
        overflow: hidden;
        height: 300px;
    }

    .carousel.slide.carousel-fade .carousel-caption {
        position: absolute;
        top: 50%;
        padding: 0;
        z-index: 5;
        transform: translateY(-50%);
        text-align: left;
        width: 70%;
    }
    .carousel-fade video {
        height: 300px !important;
    }
    .carousel-fade button {
        margin-top: 15px;
    }
    .carousel-fade img.d-block.w-100 {
        height: 300px;
    }
}