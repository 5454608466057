.mxr__stats {
    width: 100%;
    position: relative;
    padding-top: 120px;
    padding-bottom: 120px;
    background-position: center center;
    background-repeat: no-repeat;
}
.mxr__stats video {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    object-fit: cover;
    transform: translate(-50%,-50%);
}
.mxr__stats-video{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    min-height: 100%;
    min-width: 100%;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    overflow: hidden;
}
.mxr__stats-numbers{
    position: relative;
    z-index: 10;
}
.mxr__stats-number-sec {
    height: auto;
    overflow: visible;
    background: #ffffff38;
    padding: 30px;
    color: #fff;
    border-right: 1px solid #fff;
}
@media screen and (max-width: 767px){

    .mxr__stats {
        background: #4579ad !important;
    }
    .mxr__stats video, .mxr__stats-video {
        min-height: 100%;
        min-width: 100%;
        position: absolute;
        z-index: 1;
        display: none;
    }
    .mxr__stats-numbers {
        position: relative;
        z-index: 10;
        PADDING: 0PX 30PX;
    }
    .mxr__stats-number-sec {
        background: #ffffff38;
        border-right: UNSET;
        color: #fff;
        height: auto;
        overflow: visible;
        padding: 30px;
        MARGIN-BOTTOM: 8PX;
    }
    .mxr__stats-number-sec {
        background: #ffffff38;
        border-right: unset;
        color: #fff;
        height: auto;
        overflow: visible;
        padding: 30px;
    }
    }
    
    