.mxr__stack-list img {
    -webkit-filter: saturate(2) brightness(2.5) hue-rotate(330deg);
    filter: saturate(2) brightness(2.5) hue-rotate(330deg);
}

.mxr__stack .tab-content {
    display: inline-block;
    width: 100% !important;
}
.mxr__stack-list img {
    -webkit-filter: saturate(2) brightness(2.5) hue-rotate( 330deg);
    filter: saturate(2) brightness(2.5) hue-rotate( 330deg);
}
.mxr__stack .tab-content {
    display: inline-block;
    width: 100% !important;
}
.mxr__stats-number-sec {
    height: auto;
    overflow: visible;
    background: #ffffff38;
    padding: 30px;
    color: #fff;
    border-right: 1px solid #1d1d1e !important;
}
.mxr__stats-number-txt {
    padding-left: 20px;
}
.mxr__stats-number-txt h4 {
    color: #fff !important;
    font-size: 19px;
    padding: 0 !important;
    margin: 0 !important;
    font-family: 'Poppins' !important;
    line-height: 28px;
}
@media screen and (max-width: 767px){

    .mxr__stats {
        background: #4579ad !important;
    }
    .mxr__stats video, .mxr__stats-video {
        min-height: 100%;
        min-width: 100%;
        position: absolute;
        z-index: 1;
        display: none;
    }
    .mxr__stats-numbers {
        position: relative;
        z-index: 10;
        PADDING: 0PX 30PX;
    }
    .mxr__stats-number-sec {
        background: #ffffff38;
        border-right: UNSET !important;
        color: #fff;
        height: auto;
        overflow: visible;
        padding: 30px;
        MARGIN-BOTTOM: 8PX;
    }
    }
    @media screen and (max-width: 767px){
        .mxr__stack ul.mxr__stack-list {
            float: left;
            width: 100%;
            display: flex;
            align-items: baseline;
            justify-content: unset;
            list-style: none;
            padding-left: 0;
            flex-wrap: wrap;
        }
        .mxr__stack-list li {
            width: 50% !important;
            text-align: center;
            color: #000;
            font-weight: 600;
            padding-bottom: 30px;
            box-sizing: border-box;
        }
        .mxr__tabs .nav-link.active {
            border-color: #fff #fff #4579ad;
            border-width: 2px;
            background: #f1f1f1 !important;
            border-bottom: 2px solid #3d7582 !important;
        }
        .mxr__tabs .nav-link {
            color: #777;
            padding: 10px 20px !important;
            border-color: rgb(255 255 255) !important;
            background: #d5d5d5 !important;
            margin-bottom: 7px;
            margin-right: 3px;
            border-radius: 0;
        }
        .mxr__section-title {
            font-size: 27px;
        }
        .mxr__research-borders-sec h6 {
            font-weight: 600;
            margin-top: 0;
        }
        .mxr__promise-content {
            padding: 0;
        }
        .mxr__promise .mxr__section-title {
            font-size: 27px;
            text-align: center;
        }
        .mxr__cta2.mxr__section img.w-100 {
            margin-top: 25px;
        }
        }
    