.mxr__promise{
    background:#f5f5f5;
}
.mxr__promise .mxr__section-title{
    font-size: 33px;
    color: #383838;
    font-weight: 600;
    text-align:left;
}
.mxr__promise-content {
    padding: 45px 25px 25px 0px;
}
