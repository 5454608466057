.mxr__hireus h3.mxr__section-title {
    text-align: left;
}
.mxr__hireus svg {
    color: #4579ad;
    margin-right: 12px;
}
.mxr__hireus p {
    color: #777777 !important;
}
@media screen and (max-width: 767px){
    .mxr__hireus img {
        width: 100%;
    }
}