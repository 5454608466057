.mxr__innerbanner{
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.internal_page_banner >div {
    background-position: center center;
    background-repeat: no-repeat;
    border-width: 0px 0px 0px 0px;
    border-color: #3e3e3e;
    border-style: solid;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 400px;
}
.internal_page_banner h1 {
    margin: 0px;
    color: rgb(255, 255, 255);
    --fontSize: 40;
    line-height: 1.4;
    font-weight: 700;
    text-transform: uppercase;
}
.mxr__vision .mxr__section-title {
    padding-bottom: 1rem;
}
.internal_page_banner p {
    color: #fff;
    font-size: 15px;
    line-height: 1.6;
    font-weight: 400;
}
.left_internal_page.col-lg-5.col-md-5 {
    padding-top: 8%;
}
@media screen and (max-width: 767px){
    .internal_page_banner >div {
        background: #4579add6;
    }
    .left_internal_page.col-lg-5.col-md-5 {
        padding-top: 50px;
        margin-top: 0px;
        padding-right: 30px;
        padding-bottom: 50px;
        margin-bottom: 0px;
        padding-left: 30px;
    }
}